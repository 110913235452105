import { useCallback, useMemo, useState } from 'react';
import { styled, Box, Button, Menu, MenuItem, MenuList } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PaletteIcon from '@mui/icons-material/Palette';

const THEMES = ['infinity', 'camacho'];

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const PREFIX = 'SandboxControls';

const classes = {
  sandboxButton: `${PREFIX}-sandboxButton`
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.sandboxButton}`]: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 15,
    padding: theme.spacing(0.5, 1, 0.5, 2),
    minWidth: 0,
    textTransform: 'none',
    margin: theme.spacing(0, 1)
  }
}));

function App() {
  const params = useMemo(() => {
    const search = window.location.search;
    return new URLSearchParams(search);
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    // if you have this component in a ListItemLink, you don't want the anchor to be clicked
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const [themeName, setThemeName] = useState<string>(params.get('v-theme') ?? THEMES[0]);

  const handleThemeChange = useCallback(
    (val: string) => {
      setThemeName(val);
      params.set('v-theme', val);
      window.location.search = params.toString();
    },
    [params]
  );

  return (
    <StyledBox sx={{ maxWidth: 635, mx: 'auto', mt: 1 }}>
      <Button
        variant="outlined"
        className={classes.sandboxButton}
        onClick={handleClick}
        startIcon={<PaletteIcon />}
        endIcon={<ArrowDropDownIcon />}
      >
        Theme: {toTitleCase(themeName)}
      </Button>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          sx: {
            overflow: 'visible',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 16,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
      >
        <MenuList>
          {THEMES.map((t) => (
            <MenuItem
              key={t}
              onClick={(e) => {
                handleClose();
                handleThemeChange(t);
              }}
              sx={{
                color: themeName === t ? 'primary.main' : 'text.primary'
              }}
            >
              {toTitleCase(t)}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </StyledBox>
  );
}

export default App;
